import { ArrowForward } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  autocompleteClasses,
  AutocompleteOption,
  Divider,
  FormControl,
  formControlClasses,
  FormLabel,
  Input,
  inputClasses,
  styled,
  Typography,
} from '@mui/joy';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { format } from 'date-fns';
import { atom, useAtom } from 'jotai';
import { forwardRef, useContext } from 'react';
import { getCantonNameByCode } from '../../../../admin/utils/cantons';
import { SbbLocation } from '../../../../api-client-gen';
import { TenantContext } from '../../../../pages/ServiceOffering';
import { useSBBServiceLocationAutocomplete } from '../../../../state/api/services/sbb';
import { useSkippableCallback } from '../../../../utils/useSkippableCallback';
import { DelayedCircularProgress } from '../../../DelayedCircularProgress';
import { ServiceContext, ServiceOfferingContext } from '../../Services';
import { SBBCard } from '../SBBCard';
import Trips from '../Trips';

const originStationTextAtom = atom('');
const selectedOriginStationAtom = atom<null | SbbLocation>(null);

export function ChooseTripCard() {
  const tenant = useContext(TenantContext);
  const { dateTime } = useContext(ServiceOfferingContext);
  const { id } = useContext(ServiceContext);
  const [originStationText, setOriginStationText] = useAtom(originStationTextAtom);
  const [selectedOriginStation, setSelectedOriginStation] = useAtom(selectedOriginStationAtom);
  const {
    locations: originStationOptions,
    isLoading: originStationOptionsIsLoading,
    isError: originStationOptionsIsError,
  } = useSBBServiceLocationAutocomplete({
    tenantKey: tenant.key,
    serviceKey: id,
    name: originStationText,
  });
  const createSkippableCallback = useSkippableCallback();

  return (
    <SBBCard>
      <Introduction>
        Buchen Sie Ihr <strong>kostenloses</strong> Ticket für den öffentlichen Verkehr für Ihren Termin am{' '}
        <time dateTime={dateTime}>
          {format(new Date(dateTime), 'EEEE, do MMMM yyyy')}
          {tenant.showAppointmentTime && format(new Date(dateTime), " 'um' HH:mm 'Uhr'")}
        </time>
        . Bitte wählen Sie die für Sie passende Verbindung aus.
      </Introduction>
      <SelectOriginForm>
        <FormControl>
          <FormLabel>Von</FormLabel>
          <Autocomplete
            value={selectedOriginStation}
            inputValue={originStationText}
            onChange={(_, value) => setSelectedOriginStation(value)}
            onInputChange={createSkippableCallback((skipNext) => (_, value) => {
              setOriginStationText(value);
              if (selectedOriginStation) {
                setSelectedOriginStation(null);
                // Since we reset the selected origin station (the value of the autocomplete) above, the autocomplete will try to reset the input value as well. We prevent this here by skipping the next invocation of the callback.
                skipNext();
              }
            })}
            type="text"
            options={originStationOptions ?? []}
            getOptionLabel={(option) => option.name}
            filterOptions={(options) => options}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText={
              originStationOptionsIsError ? (
                <Alert color="danger" sx={{ borderRadius: 0, flexGrow: 1 }}>
                  Leider kann momentan nicht nach Stationen gesucht werden.
                </Alert>
              ) : null
            }
            forcePopupIcon={false}
            autoHighlight
            slots={{
              listbox:
                (originStationOptions && originStationOptions.length) || originStationOptionsIsError
                  ? undefined
                  : NullListBox,
            }}
            slotProps={{
              listbox: {
                sx: { minWidth: 236, ...(originStationOptionsIsError && { padding: 0 }) },
                popperOptions: { placement: 'bottom-start' },
              },
              noOptions: { sx: { padding: 0 } },
            }}
            renderOption={(props, option, { inputValue }) => (
              <AutocompleteOption {...props}>
                <Typography level="inherit">
                  {parse(option.name, match(option.name, inputValue, { insideWords: true })).map((part, index) => (
                    <Typography
                      key={index}
                      {...(part.highlight && {
                        fontWeight: 'lg',
                        textColor: 'neutral.900',
                      })}>
                      {part.text}
                    </Typography>
                  ))}
                </Typography>
              </AutocompleteOption>
            )}
            error={originStationOptionsIsError}
            endDecorator={
              originStationOptionsIsLoading && !selectedOriginStation && <DelayedCircularProgress size="sm" />
            }
          />
        </FormControl>
        <ArrowContainer>
          <ArrowForward />
        </ArrowContainer>
        <FormControl>
          <FormLabel>Nach</FormLabel>
          <Input type="text" value={tenant.sbbLocationName} disabled />
        </FormControl>
      </SelectOriginForm>
      {selectedOriginStation && (
        <>
          <Divider inset="context" />
          {tenant.allowedCantons.includes(selectedOriginStation.canton) ? (
            <Trips originId={selectedOriginStation.id} />
          ) : (
            <Alert color="danger">
              Von der Station {selectedOriginStation.name} im Kanton {getCantonNameByCode(selectedOriginStation.canton)}{' '}
              können leider keine Tickets bestellt werden. Tickets können nur aus{' '}
              {tenant.allowedCantons.length > 1 ? 'folgenden Kantonen' : 'dem folgenden Kanton'} bestellt werden:{' '}
              {tenant.allowedCantons
                .map(getCantonNameByCode)
                .sort((a, b) => a.localeCompare(b))
                .join(', ')}
              .
            </Alert>
          )}
        </>
      )}
    </SBBCard>
  );
}

const NullListBox = forwardRef(function NullListBox() {
  return null;
});

const Introduction = styled(Typography)`
  time,
  strong {
    font-weight: 500;
    color: var(--joy-palette-neutral-900);
  }
`;

const SelectOriginForm = styled('div')`
  display: grid;
  gap: 0.5rem;

  input {
    width: 0;
  }

  > .${formControlClasses.root} {
    > .${autocompleteClasses.root}, > .${inputClasses.root} {
      min-height: 40px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
    gap: 0;
    align-items: end;

    > div {
      flex: 1;
    }

    > div:first-of-type > div {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > div:not(:first-of-type) > div {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const ArrowContainer = styled('span')`
  display: none;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
  background-color: var(--joy-palette-neutral-50);
  border-top: 1px solid var(--joy-palette-neutral-outlinedDisabledBorder);
  border-bottom: 1px solid var(--joy-palette-neutral-outlinedDisabledBorder);
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 1.5rem;
  }
`;
