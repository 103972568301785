import { animate, AnimatePresence, m } from 'framer-motion';
import { createContext, forwardRef, useCallback, useContext, useState } from 'react';
import { Navigate, useLocation, useNavigate, useOutlet } from 'react-router-dom';
import { Trip } from '../../../api-client-gen';
import { pageTransitionAnimationProps } from '../../../utils/pageTransitionAnimationProps';
import { ServiceContext } from '../Services';

export const TripContext = createContext<{
  trip: Trip | null;
  selectTrip: (trip: Trip) => void;
}>({
  trip: null,
  selectTrip: () => null,
});

export const chooseTripRoute = 'choose-trip';
export const confirmTripRoute = 'confirm-trip';
export const downloadTicketRoute = 'download-ticket';

export const SBBService = forwardRef<HTMLDivElement>(function SBBService(_, ref) {
  const navigate = useNavigate();
  const location = useLocation();
  const { stateData } = useContext(ServiceContext);
  const [trip, setTrip] = useState<Trip | null>(null);
  const selectTrip = useCallback((trip: Trip) => {
    setTrip(trip);
    void navigate(confirmTripRoute);
  }, []);

  const element = useOutlet();
  const hasTicket = !!stateData?.ticketLink;
  if (hasTicket && !location.pathname.endsWith(`/${downloadTicketRoute}`)) {
    return <Navigate to={downloadTicketRoute} replace />;
  }
  if (
    (!hasTicket && location.pathname.endsWith(`/${downloadTicketRoute}`)) ||
    (!trip && location.pathname.endsWith(`/${confirmTripRoute}`))
  ) {
    return <Navigate to={chooseTripRoute} replace />;
  }

  if (!element) return null;

  return (
    <TripContext.Provider value={{ trip, selectTrip }}>
      <AnimatePresence mode="popLayout">
        <m.div
          ref={ref}
          {...pageTransitionAnimationProps}
          key={location.pathname}
          onAnimationStart={() => animate(window.scrollY, 0, { onUpdate: (top) => window.scrollTo({ top }) })}>
          {element}
        </m.div>
      </AnimatePresence>
    </TripContext.Provider>
  );
});
