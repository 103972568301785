import { parse } from 'iso8601-duration';
import { ApiError, TenantDefinition } from '../../../../api-client-gen';

export enum SbbErrorCode {
  NO_SINGLE_OFFER_BUNDLE_FOUND_FOR_RETURN_TRIP = 'NO_SINGLE_OFFER_BUNDLE_FOUND_FOR_RETURN_TRIP',
  NO_OFFER_BUNDLE_FOUND = 'NO_OFFER_BUNDLE_FOUND',
  NO_SINGLE_OFFER_BUNDLE_FOUND = 'NO_SINGLE_OFFER_BUNDLE_FOUND',
  CONNECTION_IN_THE_PAST = 'CONNECTION_IN_THE_PAST',
  REQUEST_IN_THE_PAST = 'REQUEST_IN_THE_PAST',
  NO_TRIPS_FOUND = 'NO_TRIPS_FOUND',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export function getSbbErrorCode(mutationError: unknown) {
  if (mutationError instanceof ApiError && mutationError.body && typeof mutationError.body === 'object') {
    const body = mutationError.body as { message: string; code?: string };
    return Object.values(SbbErrorCode).includes(body.code as SbbErrorCode)
      ? (body.code as SbbErrorCode)
      : SbbErrorCode.UNKNOWN_ERROR;
  }
  return SbbErrorCode.UNKNOWN_ERROR;
}

export function getSbbErrorMessage(errorCode: SbbErrorCode, tenant: TenantDefinition, fallback: string) {
  switch (errorCode) {
    case SbbErrorCode.NO_SINGLE_OFFER_BUNDLE_FOUND_FOR_RETURN_TRIP:
      return 'Das Rückfahrtticket für diese Verbindung ist aktuell nicht buchbar. Bitte buchen Sie nur die Hinfahrt.';
    case SbbErrorCode.NO_OFFER_BUNDLE_FOUND:
      return 'Für diese Auswahl konnte leider kein Angebot gefunden werden.';
    case SbbErrorCode.NO_SINGLE_OFFER_BUNDLE_FOUND:
      return 'Für diese Auswahl konnte leider kein Angebot mit einem einzelnen Ticket gebucht werden.';
    case SbbErrorCode.CONNECTION_IN_THE_PAST:
      return 'Die Verbindung befindet sich in der Vergangenheit. Bitte wählen Sie eine spätere Verbindung.';
    case SbbErrorCode.REQUEST_IN_THE_PAST:
      return 'Der Termin befindet sich in der Vergangenheit.';
    case SbbErrorCode.NO_TRIPS_FOUND:
      return `Es gibt keine Verbindung, die mindestens ${getSbbArrivalBufferInMinutes(tenant)} Minuten vor Ihrem Termin ankommt. Bitte organisieren Sie eine alternative Anreise, damit Sie pünktlich sind.`;
  }
  return fallback;
}

function getSbbArrivalBufferInMinutes(tenant: TenantDefinition) {
  return parse(tenant.sbbArrivalBuffer).minutes;
}
